import * as React from 'react'
import styled from 'styled-components'
import { media } from '../styles/styleUtils'
import CookieConsent from 'react-cookie-consent'

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  ${media.md`

  `}
`
export const PieDePagina: React.FC = () => {
  return (
    <>
      <Footer>
        <div>Copyright © {new Date().getFullYear()}, Restaurante Borrasco</div>
        <div>
          La mejor <strong>cocina tradicional</strong> de Lerín
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Aceptar"
          cookieName="myAwesomeCookieName2"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          Nuestro sitio web utiliza cookies ¿Las aceptas?.{' '}
        </CookieConsent>
      </Footer>
    </>
  )
}
