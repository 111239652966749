import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Logo from '../images/social-media/whatsapp-icon.png'
import Navbar from '../components/Navbar'
import { media } from '../styles/styleUtils'

const WrapperNavBar = styled.div`
  display: flex;
  width: 100%;
`
export const ReservasWrapper = styled.a`
  width: 20%;
  top: 0.5em;
  right: 8em;
  background-color: #111010;
  display: flex;
  padding: 0.5rem;
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    background-color: white;
    border: solid 2px black;
    color: black;
  }
  ${media.lg`
    display:none;
  `}
`

export const WhastappLogo = styled.img`
  width: 1.5rem;
  margin-right: 2px;
`

export const WhatsappWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BarraNavegacion: React.FC = () => {
  return (
    <>
      <WrapperNavBar>
        <Navbar />  
      </WrapperNavBar>
    </>
  )
}
