import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { BarraNavegacion } from '../components/BarraNavegacion'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  animation: ${scale} 5s forwards;
  ${media.md`
  `}
`

const WrapperImageQuienesSomos = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 5%;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  bottom: 3.5rem;
  color: white;
  font-weight: 900;
  right: 3.5rem;
  font-size: 5rem;
  text-align: center;
  text-shadow: 3px 3px grey;
  z-index: 2;
  ${media.md`
    font-size: 2rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`
const IframeContacto = styled.iframe`
  width: 100%;
`
const WrapperContacto = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  iframe {
    width: 100%;
    height: 450px;
    border: none;
  }
  div {
    padding-left: 1rem;
  }
  ${media.md`
    display: block;
    div {
      width: 100%;
      text-align: center;
    }
    iframe {
    margin-top: 2rem;
    width: 100%;
  }
  `}
`

const Horarios = styled.div`
  order: 1;
  h4 {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.3rem;
  }
  ${media.md`
    width: 100%;
    margin-top: 2rem;
`}
`

const ParrafoHorarios = styled.p`
  text-align: left;
  margin-top: 0.2rem;
  ${media.md`
    text-align:center;
  `}
`
const ParrafoBold = styled.p`
  text-align: left;
  font-weight: bold;
  margin-top: 1rem;
  ${media.md`
    text-align: center;
  `}
`

export default ({ data, location }: IndexPageProps) => {
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <WrapperContacto>
            <IframeContacto
              title="Mapa del restaurante Borrasco de Lerín en google"
              src="https://maps.google.es/maps?f=q&amp;source=s_q&amp;hl=es&amp;geocode=&amp;q=restaurante+borrasco+lerin&amp;aq=&amp;sll=42.483832,-1.972615&amp;sspn=0.006741,0.016512&amp;ie=UTF8&amp;hq=restaurante+borrasco&amp;hnear=Ler%C3%ADn,+Navarra&amp;t=m&amp;z=16&amp;iwloc=A&amp;output=embed"
            ></IframeContacto>
            <Horarios>
              <h4>HORARIOS</h4>
              <ParrafoBold>Lunes a jueves: </ParrafoBold>
              <ParrafoHorarios>
                Comidas. 13:00 - 15:30. Noches cerrado.
              </ParrafoHorarios>
              <ParrafoBold>Viernes y sábado:</ParrafoBold>
              <ParrafoHorarios> Comidas. 13:00 - 15:30.</ParrafoHorarios>
              <ParrafoHorarios>Cenas. 21:00 - 23:00.</ParrafoHorarios>
              <ParrafoBold>Domingo:</ParrafoBold>
              <ParrafoHorarios>
                Comidas. 13:00 - 15:30. Noches cerrado.
              </ParrafoHorarios>
              <br />
              <h4>DONDE ESTAMOS</h4>
              <ParrafoHorarios>Calle Abadejo 2 </ParrafoHorarios>
              <ParrafoHorarios>31260 Lerín, Navarra</ParrafoHorarios>
              <br />
              <h4>RESERVAS</h4>
              <ParrafoHorarios>
                <a href="tel:+34948 53 05 61">948 53 05 61</a>
              </ParrafoHorarios>
              <ParrafoHorarios>
                <a href="tel:+34649943878">649 943 878</a>
              </ParrafoHorarios>
              <ParrafoHorarios>
                <a href="mailto:info@restauranteborrasco.com">info@restauranteborrasco.com</a>
              </ParrafoHorarios>
            </Horarios>
          </WrapperContacto>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
